/*.App {
  top: 4rem;
}*/
.content-background {
    position: fixed;
    left: 0%;
    right: 0%;
    top: 0%;
    width: 100%;
    height: 230px;
    background-color: #0d9488;
    border-radius: 0px 0px 10px 10px;
    z-index: -1;
}
.content-notch-header {
    position: fixed;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 60px;
    width: 100%;
    height: 60px;
    background-color: #0d9488;
    z-index: 11;
}
/*.header-top {
    top: -48px;
}+/
/**** Navegacion ****/
nav .desktop-navigation a:hover,
nav .desktop-navigation a.active {
    border-bottom: 4px solid #0d9488 !important;
    color: #0d9488;
}
nav .desktop-navigation a.disabled:hover,
nav .desktop-navigation a.active.disabled {
    border-bottom: none !important;
    color: #d4d4d4;

}
nav .desktop-navigation a .icon-tabler:hover,
nav .desktop-navigation a.active .icon-tabler {
    stroke: #0d9488 !important;
}
nav .desktop-navigation a.disabled .icon-tabler:hover,
nav .desktop-navigation a.active.disabled .icon-tabler {
    stroke: #d4d4d4 !important;
}
.icofcv-navbar {
    background-color: #FFFFFF;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
}
@media (max-width: 576px) {
    .icofcv-navbar {
        background-color: #0d9488;
        box-shadow: none !important;
    }
    #mobile-menu {
        z-index: 2;
    }
}
/**** Navegacion movil ****/
.nav-phone {
    border-top: 1px solid #DADADA;
    z-index: 9 !important;
}
.nav-phone a:hover,
.nav-phone a.active {
    border-top: 4px solid #0d9488 !important;
    color: #0d9488;
}
.nav-phone a.active span {
    color: #0d9488 !important;
    font-weight: 700;
}
.nav-phone a.active svg {
    stroke: #0d9488 !important;
}
.nav-phone span {
    color: #737373;
    font-size: 0.75rem;
}

/*** Carousel ***/
/*.swiper-slide {
    width: 50% !important;
}*/
.swiper-button-next, 
.swiper-button-prev {
    color: #0d9488 !important;
}
.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #0d9488 !important;
}
/** Content Card **/
.content-card {
    min-height: 200px;
}

/*** Modal ***/
.modal-body {
    max-height: 75vh;
    -webkit-overflow-scrolling: touch;
}
.full-modal-body {
    max-height: 84vh;
    -webkit-overflow-scrolling: touch;
}
.locator-modal-body {
    max-height: 80vh;
    -webkit-overflow-scrolling: touch;
}
.clinic-list-modal {
    -webkit-overflow-scrolling: touch;
}

/*** Selector Archivos ***/
#accordion-collapse-body-3 > div >[type='file'] {
     font-size: 0.75rem !important;
}

/** Listados Clinics **/
.content-cliniclist {
    height: 72vh;
}
.clinic-title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.clinic-mail {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

}
@media (max-width: 576px) {
    .content-cliniclist {
        height: calc(100vh - 290px);
    }
}

/*** Tipo Colegiados ***/
.co {
    background-color: #d1d5db;
    color: #4b5563;
    width: 2rem;
    height: 2rem;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.ej {
    background-color: #0d9488;
    color: #FFFFFF;
    width: 2rem;
    height: 2rem;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.ho {
    background-color: #fcd34d;
    color: #4b5563;
    width: 2rem;
    height: 2rem;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.no {
    background-color: #dc2626;
    color: #FFFFFF;
    width: 2rem;
    height: 2rem;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
    line-height: 1.25rem;
}

/** Forms **/
/*.css-1s2u09g-control {
    background-color: #fafafa !important;
    border-radius: 0.5rem !important;
    border: 1px solid #d1d5db !important;
}*/

/*** Toast ***/
.Toastify__toast.Toastify__toast-theme--light.Toastify__toast--default {
    border-radius: 8px !important;
    margin-top: 30px;
}
.Toastify__toast {
    border-radius: 8px !important;
}
.Toastify__close-button--light {
    color: #000;
    opacity: 0.3;
    position: absolute;
    right: 15px;
    z-index: 99;
}
.Toastify__close-button > svg {
    fill: currentColor;
    height: 24px !important;
    width: 24px !important;
}