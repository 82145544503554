.lds-ring {
    /*display: flex;
    justify-content: center;
    position: relative;
    margin: 0 auto;
    width: 80px;
    height: 80px;*/
    position: absolute;
    top: 30%;
    left: 50%;
  }
  @media (max-width: 576px) {
    .lds-ring {
        position: absolute;
        top: 20%;
        left: 38%;
      }
  }
  
  .lds-ring div {
    position: absolute;
    width: 64px;
    height: 64px;
    /*margin: 8px;*/
    border: 4px solid rgba(0, 0, 0, 0.1);
    /*border: 5px solid #0d9488;*/
    border-radius: 50%;
    animation: lds-ring 2s linear infinite;
    /*border-color: #0d9488 transparent transparent transparent;*/
    border-left-color: #0d9488;
  }
  
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }