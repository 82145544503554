.content-group {
    position: absolute;
    width: 360px;
    height: 282px;
    left: 6px;
    top: 0px;

}

.content {
    box-sizing: border-box;
    position: absolute;
    width: 360px;
    height: 282px;
    left: 6px;
    top: 0px;

    background: #FFFFFF;
    border: 1px solid #EBEBEB;
    border-radius: 10px;

}

 .content-carousel {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px 9px;
    gap: 20px;

    position: absolute;
    width: 358px;
    height: 193px;
    left: 7px;
    top: 30px;
    overflow-x: scroll;

    background: #FFFFFF;
} 


.content-item {
    height: 176px;
    width: 340px;
    left: 9px;
    top: 10px;
    border-radius: 10px;
    flex: none;
    order: 0;
    flex-grow: 0;
    
}



.title-actuality {
    position: absolute;
    width: 84px;
    height: 21px;
    left: 17px;
    top: 9px;
    
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height  */
    
    letter-spacing: -0.333333px;
    
    color: #000000;
}




 .img-carousel {
    box-sizing: border-box;
    position: absolute;
    width: 340px;
    height: 115px;
    left: 9px;
    top: 10px;
    border: 1px solid #EBEBEB;
    border-radius: 10px;
}

.content-date {
    position: absolute;
    width: 136px;
    height: 14px;
    left: 10px;
    top: 131px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
   


    color: #00A4A7;
}

.title-carousel {
    position: absolute;
    width: 339px;
    height: 35px;
    left: 10px;
    top: 151px;
    
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    
    color: #000000;
}

.content-vermas {
    position: absolute;
    width: 63px;
    height: 18px;
    left: 282px;
    top: 250px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */

    text-decoration-line: underline;

    color: #309D93;
}
  
  
