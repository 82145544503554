instagram-gallery {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
}

.instagram-item {
    -webkit-box-flex: 0;
    flex: 0 0 calc(100% / 6 - 10px);
    margin: 5px;
    display: block;
    position: relative;
}

.instagram-image {
    display: block;
    width: 100%;
    height: 100%;
}

.pagination {
  display: block;
  margin: 0 auto;
  padding: 2em;
}
  