.content-collegiatelist{
    overflow: auto;
    height: calc(100vh - 300px);
}
@media (max-width: 576px) {
    .content-collegiatelist{
        overflow: auto;
        height: calc(100vh - 445px);

    }
}
