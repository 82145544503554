.news-title {
    overflow: hidden; 
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 19px;
}
.news-description {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 19px;
    text-overflow: ellipsis;
}
.news-description>h4:first-child {
    display: none;
}
.newslist-container {
    min-height: 8rem;
}
.news-description>h4.MsoNormal>strong {
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
    color: #737373 !important;
}
.news-description>p.MsoNormal {
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
}
.news-description>p>a {
    color: #0d9488;
    text-decoration: underline;
}
.news-description>p>a:hover {
    color: #115e59;
    text-decoration: underline;
}
.image-news {
    min-width: 250px;
}
@media (max-width: 576px) {
    .image-news {
        min-width: auto !important;
    }
}
@media (min-width: 1320px) {
    .image-news {
        min-width: 300px;
        max-width: 300px;
    }
}
@media (min-width: 1620px) {
    .image-news {
        min-width: 450px;
        max-width: 450px;
    }
}
