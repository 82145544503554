@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@supports(margin: max(0px)) {
	.fixNotchPaddingTop {
		padding-top: max(env(safe-area-inset-top),8px) !important;
	}
	
	.fixNotchMarginTop {
		margin-top: max(env(safe-area-inset-top),8px)  !important;
	}
}


@supports not (margin: max(0px)) {
	.fixNotchPaddingTop {
		padding-top: 8px !important;
	}
	
	.fixNotchMarginTop {
		margin-top: 8px !important;
	}
}

.fixNotchMarginBottom {
	margin-bottom: calc(env(safe-area-inset-bottom)/2) !important
}

.fixNotchPaddingBottom {
	padding-bottom: calc(env(safe-area-inset-bottom)/2) !important
}

html, body {
    overscroll-behavior-y: none;
  }

body {
    position: fixed;
    overflow: hidden;
  }

#root {
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
   /* enables “momentum” (smooth) scrolling */
}
.btn:active, .btn:focus {
    background-color: #0d9488;
    border-color: #0d9488;
}

/** Tabs **/
.icofcv-tabs>li>button[aria-selected="true"] {
    color: #0d9488 !important;
    border-color: #0d9488 !important;
}

[type='file'] {
    font-size: 0.75rem;
}

.datepicker-main .datepicker-cell.day.selected.focused {
    background-color: #0d9488 !important;
}
.login-container .lds-ring {
    position: absolute;
    top: 40%;
    left: 45%;
}

/*** Anuncios ***/
.icofcv-ad-list-container {
    /*height: 68vh;
    overflow: auto;*/
}

.modalBodyHeight {
    max-height: 85vh !important;
}

@media only screen and (max-width: 768px) {
    .icofcv-ad-list-container {
        height: calc(100vh - 300px);
        overflow: auto;
    }
    .viewListAdvertismentsContainer {
        height: 450px;
    }
    .visorContainer {
        height: 208px !important;
    }
    .instagram-link {
        margin: auto;
    }
    .instagram-title {
        text-align: center;
    }
}

.advantagesForcedStyles p span.ql-size-small {
    --tw-text-opacity: 1 !important;
    color: rgb(115 115 115 / var(--tw-text-opacity)) !important;
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
    margin-top: 0.5rem!important;
    margin-bottom: 0.5rem!important;
}
.advantagesForcedStyles h2 span {
    --tw-text-opacity: 1 !important;
    color: rgb(115 115 115 / var(--tw-text-opacity)) !important;
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
    margin-top: 0.5rem!important;
    margin-bottom: 0.5rem!important;
}
.advantagesForcedStyles div.border-b.my-2 div {
    margin-top: 0.5rem!important;
    margin-bottom: 0.5rem!important;
}
.advantagesForcedStyles div.border-b.my-2 div ul li {
    --tw-text-opacity: 1 !important;
    color: rgb(115 115 115 / var(--tw-text-opacity)) !important;
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
    margin-top: 0.5rem!important;
    margin-bottom: 0.5rem!important;
}
