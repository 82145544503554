/*.main-container-home {
    position: absolute;
    top: 65px;
    bottom: 65px;
    overflow: auto;
    overflow-x: hidden;
    padding-bottom: 50px;
}*/
.main-container {
    overflow: hidden;
    overflow-x: hidden;
    height: 100%;
    margin-top: 47px;
    position: relative;
    /*padding-bottom: 70px;*/
}
@media (min-width: 768px) {
    .main-container {
        bottom: 0;
        top: 20px;
    }
}
.content-home {
    position: relative;
    width: 100%;
    height: 100vh;
}
.content-components {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 87px;
    overflow-y: scroll;
    background: transparent;
    z-index: 2;
}
.Home-logo {
    animation: Home-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}
.Home-header {
    /*background-color: #282c34;*/
    min-height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}
.Home-link {
    color: #0d9488;
}
.Home-api-response {
    margin-top: 10px;
    width: 50%;
    background-color: #434d63;
    display: inline-block;
    font-family: 'Lucida Console', monospace
}
  
@keyframes Home-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
}
.see-more-link {
    text-transform: uppercase;
    text-decoration: underline;
    font-size: 0.875rem;
    border-top: 1px solid #DADADA;
}
/**** Action Card ***/
.card {
    border: 1px solid #DADADA;
}
.action-card h3 {
    font-size: 0.875rem;
}
.action-card p {
    font-size: 0.875rem;
    color: #8F8F8F;
    line-height: 1.2;
}
.action-card button {
    font-size: 1rem;
}
/*** Carousel ***/
.indicators button {
    border: 1px solid #0d9488 !important;

}
.doc-title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; 
}
.guide-carousel .carousel-card .doc-title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical; 
}
.guide-carousel .carousel-card .creator-text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical; 
}
.guide-carousel .carousel-card .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: 5px !important;
}
