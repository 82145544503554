
.parrafo {
    white-space: break-spaces;
  }
.news-detail-title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.news-text-description p {
    color: #525252 !important;
    font-size: 0.875rem; /* 14px */
    line-height: 1.25rem;
}
.news-text-description a {
    color: #0d9488 !important;
    text-decoration: underline !important;
    font-size: 0.875rem; /* 14px */
    line-height: 1.25rem;
}
h4.MsoNormal>strong {
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    font-size: 1.rem !important;
    line-height: 1.5rem !important;
    font-weight: 500 !important;
    color: #000 !important;
}
p.MsoNormal {
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
}
@media (max-width: 576px) {}
